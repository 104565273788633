<template>
    <div class="container">

        <input type="text" v-model="postBody" @change="postPost()"/>
        <textarea v-model="message" placeholder="ajoutez plusieurs lignes"></textarea>

        <ul v-if="errors && errors.length">
            <!--<li v-for="error of errors" v-bind:"dededzdze">-->
                <!--{{error.message}}-->
            <!--</li>-->
        </ul>

    </div>
</template>

<style lang="scss" scoped>
    .container{
        margin-top: 100px;
        position: absolute;
    }
</style>

<script>
    import axios from 'axios';
    const querystring = require("querystring");

    export default {
        data() {
            return {
                postBody: '',
                form: {
                    name1: 'toto',
                    position: true
                },
                errors: []
            };
        },
        methods: {
            // Pushes posts to the server when called.
            postPost() {
                console.log('postPost');

                axios.post(`api.php`, querystring.stringify(this.form))
                    .then(response => {
                        console.log("response:");
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log("error");
                        this.errors.push(e)
                    })

                // async / await version (postPost() becomes async postPost())
                //
                // try {
                //   await axios.post(`http://jsonplaceholder.typicode.com/posts`, {
                //     body: this.postBody
                //   })
                // } catch (e) {
                //   this.errors.push(e)
                // }
            }
        },
    }
</script>
